import React, { useEffect, useState } from "react";
import Card from "../../Components/Card";
import Title from "../../Components/Title";
import TextInput from "../../Components/TextInput";
import PrimaryButton from "../../Components/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useUser } from "../../Utils/Context/UserContext";
import Loader from "../../Components/Loader";

export default function Personal() {
  const { t } = useTranslation();
  const { update, isLoading, user, isSuccessUpdate } = useUser();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [user_id, setUser_id] = useState("");
  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    setName(user?.name);
    setEmail(user?.email);
    setUser_id(user?.id);
  }, []);

  useEffect(() => {
    if (isSuccessUpdate) {
      const userString = JSON.stringify(user);
      sessionStorage.setItem("user", userString);
    }
  }, [isSuccessUpdate, user]);

  const attemptUpdate = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("user[name]", name);
    data.append("user[email]", email);
    update(data, user_id);
  };
  return isLoading ? (
    <div className="w-full flex justify-center items-center">
      <Loader />
    </div>
  ) : (
    <div>
      <Card className={`flex flex-col`}>
        <form onSubmit={attemptUpdate}>
          <Title>{t("Personal Data")}</Title>
          <div className={`grid grid-cols-1 md:grid-cols-2 gap-3`}>
            <TextInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full"
              placeholder={t("Name")}
            />
            <TextInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full"
              placeholder={t("Email")}
            />
          </div>
          <div className="flex justify-center items-center mt-5">
            <PrimaryButton disabled={isLoading} type="submit" className="w-fit">
              {t("Update")}
            </PrimaryButton>
          </div>
        </form>
      </Card>
    </div>
  );
}
