import React, { useState } from "react";
import { APP_NAME, SIDEBAR_MENU } from "../Utils/Constant";
import SideLink from "./SideLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ApplicationLogo from "./ApplicationLogo";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Sidebar() {
  const location = useLocation();
  const { t } = useTranslation();
  const userRoles = JSON.parse(sessionStorage.getItem("roles"));

  // State to track expanded/collapsed state of each parent item
  const [expandedItems, setExpandedItems] = useState([]);

  // Function to toggle the expanded state of a parent item
  const toggleExpand = (index) => {
    setExpandedItems((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  return (
    <div className="hidden fixed flex-col w-64 bg-white dark:bg-gray-800 h-screen md:flex border-r">
      <div className="h-full pb-36">
        <div className="flex items-center justify-center mt-8">
          <ApplicationLogo className="block h-20 w-auto fill-current text-gray-800" />
        </div>
        <nav className="mt-10 flex flex-col pb-16">
          {SIDEBAR_MENU.map((item, index) => {
            const isAllowed = userRoles.some((userRole) =>
              item.allowedRoles.includes(userRole.name)
            );

            if (isAllowed) {
              return (
                <React.Fragment key={index}>
                  {/* Parent Item */}
                  <SideLink
                    href={item.route}
                    onClick={() => toggleExpand(index)}
                    active={location.pathname === item.route ? "active" : ""}
                  >
                    <div className="flex items-center">
                      <FontAwesomeIcon
                        icon={item.icon}
                        className="mr-3 bg-primary w-5 h-5 p-1 rounded-full text-white"
                      />
                      <span className="text-gray-800 dark:text-slate-100 font-medium">
                        {t(item.title)}
                      </span>
                    </div>

                    {item.children && (
                      <FontAwesomeIcon
                        icon={expandedItems[index] ? faAngleDown : faAngleRight}
                        className="mr-3 text-gray-500"
                      />
                    )}
                  </SideLink>

                  {/* Children */}
                  {item.children && expandedItems[index] && (
                    <div className="ml-4">
                      {item.children
                        .filter((child) =>
                          child.allowedRoles.some((role) =>
                            userRoles.some((userRole) => userRole.name === role)
                          )
                        )
                        .map((child, childIndex) => (
                          <SideLink
                            key={childIndex}
                            href={child.route}
                            active={
                              location.pathname === child.route ? "active" : ""
                            }
                          >
                            <div className="flex items-center">
                              <FontAwesomeIcon
                                icon={child.icon}
                                className="mr-3 bg-primary w-5 h-5 p-1 rounded-full text-white"
                              />
                              <span className="text-gray-800 dark:text-slate-100 font-medium">
                                {t(child.title)}
                              </span>
                            </div>
                          </SideLink>
                        ))}
                    </div>
                  )}
                </React.Fragment>
              );
            }

            return null;
          })}
        </nav>
      </div>
    </div>
  );
}
