import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../Components/PrimaryButton";
import TableHeader from "../../../Components/TableHeader";
import TableBody from "../../../Components/TableBody";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination } from "flowbite-react";
import { faMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Search from "../../../Components/Search";
import Loader from "../../../Components/Loader";
import { useTemplate } from "../../../Utils/Context/TemplateContext";
import toast from "react-hot-toast";

export default function TableTemplate() {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deletationId, setDeletationId] = useState(null);

  const { fetch, templates, isLoading, destroy } = useTemplate();
  const { t } = useTranslation();
  useEffect(() => {
    fetch({ page: page, keyword: keyword });
  }, [page, keyword]);

  const heads = [t("No."), t("Name"), t("Action")];

  const handleModal = (templateId) => {
    setShowModal(!showModal);
    setDeletationId(templateId);
  };

  const destroyTemplate = async () => {
    destroy(deletationId).then(() => {
      fetch({ page: page, keyword: keyword });
      setDeletationId(null);
      setShowModal(false);
      toast.success(t("Template has been deleted"));
    });
  };

  return isLoading ? (
    <div className="w-full flex justify-center">
      <Loader />
    </div>
  ) : (
    <>
      {showModal && (
        <div
          className="fixed inset-0 z-50 bg-black bg-opacity-70"
          onClick={() => setShowModal(false)}
        >
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-slate-900 rounded-md shadow-lg p-5"
            onClick={(e) => e.stopPropagation()}
          >
            <p className="text-lg my-3">
              {t("Are you sure you want to delete this template?")}
            </p>
            <div className="flex justify-end gap-3">
              <PrimaryButton onClick={() => destroyTemplate()}>
                Yes
              </PrimaryButton>
              <PrimaryButton onClick={() => setShowModal(false)}>
                No
              </PrimaryButton>
            </div>
          </div>
        </div>
      )}

      <div className="overflow-x-auto">
        <p className="font-bold text-lg text-center md:text-left my-3">
          Total: {templates?.total}
        </p>
        <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
          <Search onSubmit={(e) => setKeyword(e.keyword)} />
          <Link to={`/template/create`}>
            <PrimaryButton className="my-5 w-full md:w-fit">
              <p className="w-full text-center">
                <FontAwesomeIcon className="mr-3" icon={faPlus} />
                {t("Add new template")}
              </p>
            </PrimaryButton>
          </Link>
        </div>
        <table className="w-full">
          <thead>
            <tr className="text-center font-bold text-xl">
              {heads.map((item, index) => (
                <TableHeader key={index} title={item} />
              ))}
            </tr>
          </thead>
          <tbody>
            {templates?.data.map((item, index) => {
              return (
                <tr key={index} className={"border-b"}>
                  <TableBody
                    className={"text-center"}
                    children={templates?.from + index}
                  />
                  <TableBody>
                    <Link
                      className="hover:bg-slate-400 truncate px-5 py-2 transition-all duration-500 rounded-full"
                      to={`/template/create?id=${item.id}`}
                    >
                      {item.name}
                    </Link>
                  </TableBody>
                  <TableBody className={"flex justify-center items-center"}>
                    <PrimaryButton
                      className="hover:bg-red-400 transition-all duration-500 rounded-full px-5 py-2"
                      onClick={() => handleModal(item.id)}
                    >
                      <p className="w-full text-center text-red-500">
                        <FontAwesomeIcon icon={faTrash} />
                      </p>
                    </PrimaryButton>
                  </TableBody>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex justify-center md:justify-end w-full p-5">
          <Pagination
            layout="pagination"
            currentPage={templates?.current_page}
            totalPages={templates?.last_page}
            onPageChange={(e) => setPage(e)}
          />
        </div>
      </div>
    </>
  );
}
