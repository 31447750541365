import Header from "../../Components/Header";
import Authenticated from "../../Layouts/Authentication";
import React from "react";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import BeginPage from "../../Components/BeginPage";
import Table from "./Partials/Table";
import ShortenLinkCreate from "./Create";

export { ShortenLinkCreate };

export default function ShortenLink() {
  const { t } = useTranslation();
  return (
    <Authenticated header={<Header>{t("Shorten Link")}</Header>}>
      <Head title={t("Shorten Link")} />
      <BeginPage>
        <Table />
      </BeginPage>
    </Authenticated>
  );
}
