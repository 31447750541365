import { useEffect, useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const useUser = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleError = (e) => {
    setIsError(true);
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };
  const handleFinish = () => {
    setIsLoading(false);
  };
  const handleInitial = () => {
    setIsLoading(true);
  };

  const fetchAll = async ({ page = 1, keyword = "" }) => {
    handleInitial();
    const config = {
      params: {
        page: page,
        "filter[name]": keyword,
        sort: "-created_at",
        "include[]": "roles",
        "filter[!roles.name]": "client",
      },
    };
    await instanceRequest("get", "user", config, undefined)
      .then((r) => {
        setIsSuccess(true);
        setUsers(r.data);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const fetchTeacher = async ({ page = 1, keyword = "" }) => {
    handleInitial();
    const config = {
      params: {
        page: page,
        "filter[name]": keyword,
        sort: "name",
        "include[]": "roles",
        "filter[roles.name]": "teacher",
      },
    };

    await instanceRequest("get", "user", config, undefined)
      .then((r) => {
        setIsSuccess(true);
        setUsers(r.data);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };
  const fetchById = async (id) => {
    handleInitial();
    const config = {
      params: {
        include: ["roles", "client"],
      },
    };
    await instanceRequest("get", `user/${id}`, config, undefined)
      .then((r) => {
        setIsSuccess(true);
        setUser(r.data);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const update = async (data, id) => {
    handleInitial();
    setIsSuccessUpdate(false);
    await instanceRequest("put", `user/${id}`, undefined, data)
      .then((r) => {
        setIsSuccessUpdate(true);
        setUser(r.data);
        toast.success(t("Success"));
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const remove = async (id) => {
    handleInitial();
    setIsSuccessUpdate(false);
    await instanceRequest("delete", `user/${id}`, undefined, undefined)
      .then(() => {
        toast.success(t("Success"));
        return true;
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  return {
    isLoading,
    isError,
    isSuccess,
    isSuccessUpdate,
    users,
    user,
    fetchAll,
    fetchById,
    fetchTeacher,
    update,
    remove,
  };
};
