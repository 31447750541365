import React, { useEffect, useState } from "react";
import Authentication from "../../Layouts/Authentication";
import Header from "../../Components/Header";
import { useTranslation } from "react-i18next";
import Head from "../../Components/Head";
import BeginPage from "../../Components/BeginPage";
import Card from "../../Components/Card";
import { Avatar } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../Utils/Context/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import InputLabel from "../../Components/InputLabel";
import PrimaryButton from "../../Components/PrimaryButton";
import TextInput from "../../Components/TextInput";
import SecondaryButton from "../../Components/SecondaryButton";

export default function Show() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    user,
    fetchById: getUserById,
    update: updateUser,
    isLoading: loadingUser,
    isSuccessUpdate,
  } = useUser();
  const { id } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    getUserById(id);
  }, []);

  const attemptUpdate = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("user[name]", name);
    data.append("user[email]", email);
    updateUser(data, user.id);
  };

  useEffect(() => {
    isSuccessUpdate && navigate("/user/" + id);
  }, [isSuccessUpdate]);

  return (
    <Authentication header={<Header>{!loadingUser && user.name}</Header>}>
      <Head title={!loadingUser && user.name} />
      <BeginPage className={``}>
        <Card
          className={`grid grid-cols-1 md:grid-cols-2 col-span-2 bg-contain md:bg-cover bg-no-repeat bg-[url("/src/Assets/bg-landmark-1.svg")]`}
        >
          <div className="w-full">
            <Avatar
              size={`xl`}
              alt="profile"
              rounded
              img={`https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`}
            />
          </div>
          <form onSubmit={attemptUpdate}>
            <div className="flex flex-col gap-3">
              <div className="overflow-hidden">
                <InputLabel>{t("Name")}</InputLabel>
                <TextInput
                  className={`w-full`}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="overflow-hidden">
                <InputLabel>{t("Email")}</InputLabel>
                <TextInput
                  className={`w-full`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="w-full gap-3 flex justify-center">
                <SecondaryButton onClick={() => navigate(-1)} type="button">
                  {t("Cancel")}
                </SecondaryButton>
                <PrimaryButton type="submit">
                  <FontAwesomeIcon icon={faSave} className="mr-3" />
                  {t("Save")}
                </PrimaryButton>
              </div>
            </div>
          </form>
        </Card>
      </BeginPage>
    </Authentication>
  );
}
