const Placemnet = {
  STARTER: 0,
  ELEMENTARY: 1,
  PRE_INTERMEDIATE: 5,
  INTERMEDIATE: 2,
  UPPER_INTERMEDIATE: 3,
  ADVANCED: 4,
};

const getLabel = (educationLevel) => {
  switch (educationLevel) {
    case Placemnet.STARTER:
      return "STARTER";
    case Placemnet.ELEMENTARY:
      return "ELEMENTARY";
    case Placemnet.INTERMEDIATE:
      return "INTERMEDIATE";
    case Placemnet.UPPER_INTERMEDIATE:
      return "UPPER INTERMEDIATE";
    case Placemnet.ADVANCED:
      return "ADVANCED";
    case Placemnet.PRE_INTERMEDIATE:
      return "PRE INTERMEDIATE";
    default:
      return "Unknown";
  }
};

export { getLabel };

export default Placemnet;
