import React, { useEffect } from "react";
import Unauthentication from "../../Layouts/Unauthentication";
import InputLabel from "../../Components/InputLabel";
import TextInput from "../../Components/TextInput";
import InputError from "../../Components/InputError";
import PrimaryButton from "../../Components/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { LogoPng } from "../../Assets";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import Degree from "../../Utils/Enums/Degree";
import Select2 from "../../Components/Select2";
import MarriageStatus from "../../Utils/Enums/MarriageStatus";
import Job from "../../Utils/Enums/Job";
import KnowFrom from "../../Utils/Enums/KnowFrom";
import HasAppliedVisa from "../../Utils/Enums/HasAppliedVisa";
import { useSchool } from "../../Utils/Context/SchoolContext";
import { Toaster } from "react-hot-toast";
import { useAuth } from "../../Utils/Context/AuthContext";
import { useState } from "react";

export default function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [birthplace, setBirthplace] = useState("");
  const [marriage_status, setStatus] = useState("");
  const [address, setAddress] = useState("");
  const [education, setEducation] = useState("");
  const [school_id, setSchool_id] = useState("");
  const [graduated_at, setGraduated_at] = useState("");
  const [work, setWork] = useState("");
  const [know_from, setKnow_from] = useState("");
  const [has_applied_visa_before, setHas_applied_visa_before] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const { schools, fetchAll: fetchSchool } = useSchool();

  const {
    register,
    isSuccess: successRegister,
    isError: errorRegister,
    isLoading: loadingRegister,
    errorMessage: errorMessageRegister,
  } = useAuth();

  const attemptRegister = async (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append("user[name]", name);
    form.append("user[phone]", phone);
    form.append("user[email]", email);
    form.append("user[marriage_status]", marriage_status);
    form.append("user[birthplace]", birthplace);
    form.append("user[birthday]", birthday);
    form.append("user[password]", password);
    form.append("user[address]", address);
    form.append("user[password_confirmation]", password_confirmation);
    form.append("client[work]", work);
    form.append("client[education]", education);
    form.append("client[know_from]", know_from);
    form.append("client[has_applied_visa_before]", has_applied_visa_before);
    form.append("client[school_id]", school_id);
    form.append("client[graduated_at]", graduated_at);
    form.append("is_client", true);
    await register(form);
  };

  useEffect(() => {
    successRegister && navigate("/login");
    errorRegister && handleErrorRegister();
  }, [successRegister, errorRegister]);

  const handleErrorRegister = () => {
    setPassword("");
    setPassword_confirmation("");
  };

  return (
    <Unauthentication formIsLeft={true}>
      <Head title={t("Register")} />
      <div className="w-full mt-12">
        <Toaster />
        <form onSubmit={attemptRegister}>
          <div className="bg-white dark:bg-slate-800 rounded-3xl shadow-md p-5 md:p-10">
            <div>
              <InputLabel htmlFor="name" value={t("Name") + "*"} />

              <TextInput
                id="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                className="mt-1 block w-full"
                autoComplete="name"
                isFocused={true}
                required
                isError={errorMessageRegister?.["user.name"]}
              />

              <InputError
                message={errorMessageRegister?.["user.name"]?.[0] ?? ""}
                className="mt-2"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <InputLabel htmlFor="phone" value={t("Phone") + "*"} />

                <TextInput
                  id="phone"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  className="mt-1 block w-full"
                  autoComplete="phone"
                  required
                  isError={errorMessageRegister?.["user.phone"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.phone"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div className="">
                <InputLabel htmlFor="email" value={t("Email") + "*"} />

                <TextInput
                  id="email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="mt-1 block w-full"
                  autoComplete="username"
                  required
                  isError={errorMessageRegister?.["user.email"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.email"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div className="">
                <InputLabel
                  htmlFor="birthplace"
                  value={t("Birth Place") + "*"}
                />

                <TextInput
                  id="birthplace"
                  onChange={(e) => setBirthplace(e.target.value)}
                  value={birthplace}
                  className="mt-1 block w-full"
                  required
                  isError={errorMessageRegister?.["user.birthplace"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.birthplace"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>
              <div>
                <InputLabel
                  htmlFor="birthday"
                  value={t("Date of birth") + "*"}
                />

                <TextInput
                  id="birthday"
                  onChange={(e) => setBirthday(e.target.value)}
                  value={birthday}
                  type="date"
                  className="mt-1 block w-full"
                  required
                  isError={errorMessageRegister?.["user.birthday"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.birthday"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div className="">
                <InputLabel
                  htmlFor="marriage_status"
                  value={t("Marital Status") + "*"}
                />

                <Select2
                  options={Object.entries(MarriageStatus)}
                  onSelected={(e) => setStatus(e[1])}
                  onTextChanged={() => null}
                  translate={`MarriageStatus_`}
                />

                <InputError
                  message={
                    errorMessageRegister?.["client.marriage_status"]?.[0] ?? ""
                  }
                  className="mt-2"
                />
              </div>
            </div>

            <div>
              <InputLabel htmlFor="address" value={t("Address") + "*"} />

              <TextInput
                id="address"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                className="mt-1 block w-full"
                autoComplete="address"
                useTextArea
                required
                isError={errorMessageRegister?.["user.address"]}
              />

              <InputError
                message={errorMessageRegister?.["user.address"]?.[0] ?? ""}
                className="mt-2"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div className="">
                <InputLabel
                  htmlFor="education"
                  value={t("Last Education") + "*"}
                />

                <Select2
                  options={Object.entries(Degree)}
                  onSelected={(e) => setEducation(e[1])}
                  onTextChanged={() => null}
                  translate={`Degree_`}
                />

                <InputError
                  message={
                    errorMessageRegister?.["client.education"]?.[0] ?? ""
                  }
                  className="mt-2"
                />
              </div>

              <div>
                <InputLabel htmlFor="school_id" value={t("School") + "*"} />

                <Select2
                  isSearchable
                  options={schools?.data}
                  onSelected={(e) => setSchool_id(e.id)}
                  onTextChanged={(keyword) => fetchSchool(keyword)}
                />

                <InputError
                  message={errorMessageRegister?.["user.school_id"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div className="">
                <InputLabel
                  htmlFor="graduated_at"
                  value={t("Graduated at") + "**"}
                />

                <TextInput
                  id="graduated_at"
                  type="date"
                  onChange={(e) => setGraduated_at(e.target.value)}
                  value={graduated_at}
                  className="mt-1 block w-full"
                  required
                  isError={errorMessageRegister?.["client.graduated_at"]}
                />

                <InputError
                  message={
                    errorMessageRegister?.["client.graduated_at"]?.[0] ?? ""
                  }
                  className="mt-2"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <InputLabel htmlFor="work" value={t("Current Job") + "*"} />

                <Select2
                  options={Object.entries(Job)}
                  onSelected={(e) => setWork(e[1])}
                  onTextChanged={() => null}
                  translate={`Work_`}
                />

                <InputError
                  message={errorMessageRegister?.["client.work"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div className="">
                <InputLabel
                  htmlFor="know_from"
                  value={t("How Did You Hear About Us?") + "*"}
                />

                <Select2
                  options={Object.entries(KnowFrom)}
                  onSelected={(e) => setKnow_from(e[1])}
                  onTextChanged={() => null}
                  translate={`KnowFrom_`}
                />

                <InputError
                  message={
                    errorMessageRegister?.["client.know_from"]?.[0] ?? ""
                  }
                  className="mt-2"
                />
              </div>
            </div>

            <div>
              <InputLabel
                htmlFor="has_applied_visa_before"
                value={t("Have You Applied for a Visa Before?") + "*"}
              />

              <Select2
                options={Object.entries(HasAppliedVisa)}
                onSelected={(e) => setHas_applied_visa_before(e[1])}
                onTextChanged={() => null}
                translate={`HasAppliedVisa_`}
              />

              <InputError
                message={
                  errorMessageRegister?.[
                    "client.has_applied_visa_before"
                  ]?.[0] ?? ""
                }
                className="mt-2"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div className="mt-4">
                <InputLabel htmlFor="password" value={t("Password") + "*"} />

                <TextInput
                  id="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  className="mt-1 block w-full"
                  autoComplete="new-password"
                  required
                  isError={errorMessageRegister?.["user.password"]}
                />

                <InputError
                  message={errorMessageRegister?.["user.password"]?.[0] ?? ""}
                  className="mt-2"
                />
              </div>

              <div className="mt-4">
                <InputLabel
                  htmlFor="password_confirmation"
                  value={t("Confirm Password") + "*"}
                />

                <TextInput
                  id="password_confirmation"
                  type="password"
                  onChange={(e) => setPassword_confirmation(e.target.value)}
                  value={password_confirmation}
                  className="mt-1 block w-full"
                  autoComplete="new-password"
                  required
                  isError={errorMessageRegister?.["user.password"]}
                />

                <InputError
                  message={
                    errorMessageRegister?.["user.password_confirmation"]?.[0] ??
                    ""
                  }
                  className="mt-2"
                />
              </div>
            </div>
          </div>

          <div className="my-3">
            <p className="text-xs italic text-red-400 px-5">
              {"*" + t("Required.")}
            </p>
            <p className="text-xs italic text-red-400 px-5">
              {"**" +
                t(
                  "If you are currently an active student, please you provide with the expected date of your graduation! If you have already graduated, kindly provide the date of your graduation."
                )}
            </p>
          </div>

          <div className="flex flex-col items-center gap-3 justify-end mt-4">
            <PrimaryButton disabled={loadingRegister} type="submit">
              {t("Register")}
            </PrimaryButton>
            <Link
              to={"/login"}
              className="underline text-sm text-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary dark:focus:ring-offset-gray-800"
            >
              {t("Already registered?")}
            </Link>
            <img
              src={LogoPng}
              alt="Logo"
              className="block md:hidden w-32 md:h-auto"
            />
          </div>
        </form>
      </div>
    </Unauthentication>
  );
}
