import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const usePayable = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    isSuccess: false,
    isSuccessGet: false,
    isSuccessUpdate: false,
    isSuccessDestroy: false,
  });
  const [payables, setPayables] = useState(null);
  const [payable, setPayable] = useState(null);

  const handleApiError = (e) => {
    setStatus({ isLoading: false, isError: true, isSuccess: false });
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };

  const handleFinish = (successType) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      isLoading: false,
      isError: false,
      [successType]: true,
    }));
  };
  const handleInitial = () =>
    setStatus({
      isLoading: true,
      isError: false,
      isSuccess: false,
      isSuccessGet: false,
      isSuccessUpdate: false,
      isSuccessDestroy: false,
    });

  const fetchData = async (request, endpoint, config, data, successType) => {
    handleInitial();
    try {
      const response = await instanceRequest(request, endpoint, config, data);
      handleFinish(successType);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchAll = async ({
    page = 1,
    keyword = "",
    limit = 10,
    program_id = null,
  }) => {
    const config = {
      params: {
        page: page,
        limit: limit,
        include: ["program"],
        "filter[name]": keyword,
        sort: "created_at",
        "filter[program_id]": program_id,
      },
    };
    setPayables(
      await fetchData("get", "payable", config, undefined, "isSuccessGet")
    );
  };

  const fetchReletedPayableByClientId = async ({ client_id }) => {
    const config = {
      params: {
        include: ["program"],
        "filter[client_id]": client_id,
      },
    };
    setPayables(
      await fetchData("get", "payable", config, undefined, "isSuccessGet")
    );
  };

  const fetchById = async (id) => {
    const config = {
      params: {
        include: ["program"],
      },
    };
    setPayable(
      await fetchData("get", `payable/${id}`, config, undefined, "isSuccessGet")
    );
  };

  const store = async (data) => {
    setPayable(
      await fetchData("post", `payable`, undefined, data, "isSuccess")
    );
  };

  const update = async (id, data) => {
    const result = await fetchData(
      "put",
      `payable/${id}`,
      undefined,
      data,
      "isSuccessUpdate"
    );
    return result;
  };

  const remove = async (id) => {
    await fetchData(
      "delete",
      `payable/${id}`,
      undefined,
      undefined,
      "isSuccessDestroy"
    );
  };

  return {
    ...status,
    payables,
    payable,
    store,
    update,
    remove,
    fetchAll,
    fetchById,
    fetchReletedPayableByClientId,
  };
};
