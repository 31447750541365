import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../Components/PrimaryButton";
import TableHeader from "../../../Components/TableHeader";
import TableBody from "../../../Components/TableBody";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination } from "flowbite-react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Search from "../../../Components/Search";
import Loader from "../../../Components/Loader";
import { useSchool } from "../../../Utils/Context/SchoolContext";

export default function TableProgram() {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const { fetch, schools, isLoading } = useSchool();
  const { t } = useTranslation();
  useEffect(() => {
    fetch({ page: page, keyword: keyword });
  }, [page, keyword]);

  const heads = [t("No."), t("Name")];

  return isLoading ? (
    <div className="w-full flex justify-center">
      <Loader />
    </div>
  ) : (
    <>
      <div className="overflow-x-auto">
        <p className="font-bold text-lg text-center md:text-left my-3">
          Total: {schools?.total}
        </p>
        <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
          <Search onSubmit={(e) => setKeyword(e.keyword)} />
          <Link to={`/school/create`}>
            <PrimaryButton className="my-5 w-full md:w-fit">
              <p className="w-full text-center">
                <FontAwesomeIcon className="mr-3" icon={faPlus} />
                {t("Add new school")}
              </p>
            </PrimaryButton>
          </Link>
        </div>
        <table className="w-full">
          <thead>
            <tr className="text-center font-bold text-xl">
              {heads.map((item, index) => (
                <TableHeader key={index} title={item} />
              ))}
            </tr>
          </thead>
          <tbody>
            {schools !== null &&
              schools.data.map((item, index) => {
                return (
                  <tr key={index} className={"border-b"}>
                    <TableBody
                      className={"text-center"}
                      children={schools.from + index}
                    />
                    <TableBody>
                      <Link
                        className="hover:bg-slate-400 truncate px-5 py-2 transition-all duration-500 rounded-full"
                        to={`/school/create?id=${item.id}`}
                      >
                        {item.name}
                      </Link>
                    </TableBody>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="flex justify-center md:justify-end w-full p-5">
          <Pagination
            layout="pagination"
            currentPage={schools.current_page}
            totalPages={schools.last_page}
            onPageChange={(e) => setPage(e)}
          />
        </div>
      </div>
    </>
  );
}
