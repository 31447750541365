import React from "react";
import Card from "../../../Components/Card";
import { Label } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Title from "../../../Components/Title";

export default function MetadataClient({ client }) {
  const { t } = useTranslation();

  // Ensure client.metadata is an array
  const jsonData = JSON.parse(client?.metadata) ?? [];

  return (
    <div>
      <div className="relative">
        <div className="absolute top-5 right-10">
          <Link to={`/client/metadata/${client.id}`}>
            <button className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded-xl">
              <FontAwesomeIcon icon={faPencil} />
            </button>
          </Link>
        </div>
      </div>

      <Card className={`flex flex-col gap-3`}>
        <Title>{t("Metadata")}</Title>
        {jsonData.length > 0 &&
          jsonData.map((item, index) => (
            <div key={index}>
              <Label className="text-lg bg-slate-700 p-1 rounded-xl">
                {t(item.name)}
              </Label>
              <p className="ml-3 border-l pl-2 my-3">{item.value}</p>
              <ul className="ml-3">
                {item.list &&
                  item.list.map((subItem, subIndex) => (
                    <li key={subIndex}>
                      <span className="font-bold">{t(subItem.name)}:</span>{" "}
                      {subItem.value}
                    </li>
                  ))}
              </ul>
            </div>
          ))}
      </Card>
    </div>
  );
}
