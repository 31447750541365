import React, { useEffect, useState } from "react";
import { useClientProgram } from "../Utils/Context/ClientProgramContext";
import { useClient } from "../Utils/Context/ClientContext";
import { useTranslation } from "react-i18next";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import { Pagination } from "flowbite-react";
import moment from "moment";
import PrimaryButton from "./PrimaryButton";
import toast from "react-hot-toast";

export default function ProgramList({ user_id }) {
  const { fetchByClientId, clientPrograms, remove } = useClientProgram();
  const { fetchByUserId, client } = useClient();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    fetchByUserId({ user_id: user_id, page: page });
  }, [page]);

  useEffect(() => {
    if (client) {
      if (client.data.length > 0) {
        setClientId(client.data[0].id);
      }
    }
  }, [client]);

  useEffect(() => {
    if (clientId) {
      fetchByClientId(clientId);
    }
  }, [clientId]);

  const removeConfirmation = (id) => {
    if (window.confirm(t("Are you sure you want to delete?"))) {
      remove(id);
      toast.success(t("Success delete"));
    }
  };

  const heads = [t("No."), t("Program name"), t("Join Date"), t("Action")];

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="text-center font-bold text-xl">
            {heads.map((item, index) => (
              <TableHeader key={index} title={item} />
            ))}
          </tr>
        </thead>
        <tbody>
          {clientPrograms !== null &&
            clientPrograms.data.map((item, index) => {
              return (
                <tr key={index} className={"border-b"}>
                  <TableBody
                    className={"text-center"}
                    children={clientPrograms.from + index}
                  />
                  <TableBody>{item.program.name}</TableBody>
                  <TableBody>
                    {moment(item.created_at).format("DD MMM YYYY")}
                  </TableBody>
                  <TableBody
                    className={
                      "text-center p-0 grid cursor-pointer grid-cols-1 md:grid-cols-1 gap-2"
                    }
                  >
                    <PrimaryButton
                      onClick={() => removeConfirmation(item.id)}
                      className="flex m-1 w-fit justify-center"
                    >
                      Delete
                    </PrimaryButton>
                  </TableBody>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="flex justify-center md:justify-end w-full p-5">
        {clientPrograms && (
          <>
            <Pagination
              className="hidden md:block"
              layout="pagination"
              currentPage={clientPrograms.current_page}
              totalPages={clientPrograms.last_page}
              onPageChange={(e) => setPage(e)}
            />
            <Pagination
              className="block md:hidden"
              layout="navigation"
              currentPage={clientPrograms.current_page}
              totalPages={clientPrograms.last_page}
              onPageChange={(e) => setPage(e)}
            />
          </>
        )}
      </div>
    </div>
  );
}
