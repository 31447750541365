const UnderlineInput = ({ placeholder, onChange, value, className }) => {
  return (
    <>
      <label className="text-xs">{placeholder}</label>
      <input
        type="text"
        className={`my-1 w-full px-4 py-2 border-t-0 border-x-0 border-b rounded-bl-xl border-l-2 dark:border-b-white border-b-primary dark:bg-slate-500 ${className}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default UnderlineInput;
