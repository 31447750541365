import React from "react";
import { Link } from "react-router-dom";

export default function SideLink({ active, children, href, ...props }) {
  return (
    <Link
      {...props}
      className={`flex px-8 py-5 justify-between items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-500 transition-all duration-500 ${
        active ? "bg-gray-200 dark:bg-slate-400" : ""
      }`}
      to={href}
    >
      {children}
    </Link>
  );
}
