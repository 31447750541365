import React, { useEffect, useState } from "react";
import Authentication from "../../Layouts/Authentication";
import Header from "../../Components/Header";
import PrimaryButton from "../../Components/PrimaryButton";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useShortenLink } from "../../Utils/Context/ShortenLinkContext";

export default function Create() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shortenlink_id = queryParams.get("id");

  const { t } = useTranslation();
  const { store, isSuccess, fetchById, shortenlink } = useShortenLink();
  const navigate = useNavigate();

  const [name, setName] = useState(shortenlink?.name ?? null);
  const [shorten, setShorten] = useState(shortenlink?.shorten ?? null);
  const [originalUrl, setOriginalLink] = useState(
    shortenlink?.original_url ?? null
  );

  useEffect(() => {
    async function getData() {
      await fetchById(shortenlink_id);
    }
    if (shortenlink_id) getData();
  }, [shortenlink_id]);

  useEffect(() => {
    if (shortenlink) {
      setName(shortenlink.name ?? "");
      setShorten(shortenlink.shorten ?? "");
      setOriginalLink(shortenlink.original_url ?? "");
    }
  }, [shortenlink]);

  const storeExe = async (e) => {
    e.preventDefault();
    const data = {
      name,
      shorten,
      original_url: originalUrl,
    };

    await store(data);
  };

  useEffect(() => {
    function successHandle() {
      navigate(-1);
      toast.success(t("Shorten link added successfully"));
    }
    isSuccess && successHandle();
  }, [isSuccess]);

  return (
    <Authentication header={<Header>{t("Add new shorten link")}</Header>}>
      <Head title={t("Add new shorten link")} />
      <div className="p-10 w-screen md:w-auto dark:text-slate-200">
        <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
          <form
            className="w-full"
            onSubmit={storeExe}
            encType="multipart/form-data"
          >
            <div className="grid grid-cols-1 md:grid-cols-1 gap-5">
              <div>
                <InputLabel>{t("Name")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={name}
                  placeholder={t("Name")}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <InputLabel>{t("Shorten Link")}</InputLabel>
                <div className="flex">
                  <TextInput
                    className="w-fit"
                    value={`https://konsultanvisa.com/`}
                    disabled
                  />
                  <TextInput
                    className="w-full"
                    value={shorten}
                    placeholder={t("Shorten Link (e.g. kv-event)")}
                    onChange={(e) => setShorten(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <InputLabel>{t("Original Link")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={originalUrl}
                  placeholder={t("Original Link")}
                  onChange={(e) => setOriginalLink(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <PrimaryButton className="my-5 w-full md:w-fit">
                <p className="w-full text-center">Submit</p>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </Authentication>
  );
}
