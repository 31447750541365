import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const useVisit = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    isSuccess: false,
  });
  const [visits, setVisits] = useState(null);
  const [visit, setVisit] = useState(null);

  const handleApiError = (e) => {
    setStatus({ isLoading: false, isError: true, isSuccess: false });
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };

  const handleFinish = () =>
    setStatus({ isLoading: false, isError: false, isSuccess: true });
  const handleInitial = () =>
    setStatus({ isLoading: true, isError: false, isSuccess: false });

  const fetchData = async (request, endpoint, config, data) => {
    handleInitial();
    try {
      const response = await instanceRequest(request, endpoint, config, data);
      handleFinish();
      return response.data;
    } catch (error) {
      handleApiError(error);
      return false;
    }
  };

  const fetchAll = async () => {
    const config = {
      params: {
        include: ["program"],
        sort: "-created_at",
      },
    };
    setVisits(await fetchData("get", "visit", config, undefined));
  };

  const store = async (user_id, service) => {
    let data = {
      user_id: user_id,
      service: service,
    };
    setVisit(await fetchData("post", `visit`, undefined, data));
  };

  return {
    ...status,
    visits,
    visit,
    store,
    fetchAll,
  };
};
