import Header from "../../Components/Header";
import Authenticated from "../../Layouts/Authentication";
import React from "react";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import BeginPage from "../../Components/BeginPage";
import TableProgram from "./Partials/TableSchool";
import SchoolCreate from "./Create";

export { SchoolCreate };

export default function School() {
  const { t } = useTranslation();
  return (
    <Authenticated header={<Header>{t("School")}</Header>}>
      <Head title={t("School")} />
      <BeginPage>
        <TableProgram />
      </BeginPage>
    </Authenticated>
  );
}
