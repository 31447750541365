import React, { useEffect } from "react";
import Authentication from "../../Layouts/Authentication";
import Header from "../../Components/Header";
import { useTranslation } from "react-i18next";
import Head from "../../Components/Head";
import BeginPage from "../../Components/BeginPage";
import Card from "../../Components/Card";
import { Avatar, Tooltip } from "flowbite-react";
import {
  faArrowRight,
  faBusinessTime,
  faCalendar,
  faEnvelope,
  faFileCircleCheck,
  faGraduationCap,
  faHeart,
  faHome,
  faLightbulb,
  faMapPin,
  faPencil,
  faPhone,
  faPlus,
  faSchool,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { useClient } from "../../Utils/Context/ClientContext";
import ServiceProgress from "../../Sections/ServiceProgress";
import Title from "../../Components/Title";
import ListWithIcon from "../../Components/ListWithIcon";
import { ProfileUserSvg } from "../../Assets";
import Loader from "../../Components/Loader";
import { getLabel } from "../../Utils/Enums/Degree";
import { getServiceLabel } from "../../Utils/Enums/Service";
import moment from "moment";
import { getMarriageStatusLabel } from "../../Utils/Enums/MarriageStatus";
import { getJobLabel } from "../../Utils/Enums/Job";
import { getVisaStatusLabel } from "../../Utils/Enums/HasAppliedVisa";
import PrimaryButton from "../../Components/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHandledBy } from "../../Utils/Context/HandledByContext";
import MetadataClient from "./Partials/MetadataClient";
import { getKnowFromLabel } from "../../Utils/Enums/KnowFrom";

export default function Show() {
  const { t } = useTranslation();
  const {
    client,
    fetchById: getClientById,
    isLoading: loadingClient,
  } = useClient();
  const { store, isSuccess: successHandledBy } = useHandledBy();
  const { id } = useParams();

  useEffect(() => {
    getClientById(id);
  }, [successHandledBy]);

  return (
    <Authentication
      header={<Header>{!loadingClient && client?.user?.name}</Header>}
    >
      <Head title={!loadingClient && client?.user?.name} />
      <BeginPage className={``}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 p-5">
          <div className="flex justify-center">
            <Avatar size={`xl`} alt="profile" img={ProfileUserSvg} />
          </div>
          <div>
            <Title className={`flex gap-3`}>
              {loadingClient ? <Loader /> : client?.user?.name}{" "}
              <Tooltip content="Edit">
                <Link to={`/client/register?client_id=${client?.id}`}>
                  <FontAwesomeIcon
                    className="bg-slate-300 p-1 rounded-md hover:bg-slate-500 text-slate-500 hover:text-slate-300"
                    icon={faPencil}
                  />
                </Link>
              </Tooltip>
            </Title>
            {client?.school && (
              <ListWithIcon
                icon={faSchool}
                children={
                  loadingClient ? (
                    <Loader />
                  ) : (
                    `${client?.school?.name} (${getLabel(
                      client?.education
                    )} - ${moment(client?.graduated_at).format("MMM Y")})`
                  )
                }
              />
            )}
            {client?.major !== undefined && client?.major !== null && (
              <ListWithIcon
                icon={faGraduationCap}
                children={loadingClient ? <Loader /> : client?.major}
              />
            )}

            <ListWithIcon
              icon={faEnvelope}
              children={loadingClient ? <Loader /> : client?.user?.email}
            />
            <ListWithIcon
              icon={faPhone}
              children={loadingClient ? <Loader /> : client?.user?.phone}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-5 md:grid-cols-4 justify-center">
          <Link className="w-full relative">
            <PrimaryButton
              onClick={() => store(client?.id)}
              className="flex m-1 w-full justify-center bg-yellow-400 dark:bg-yellow-400 hover:bg-yellow-200 dark:hover:bg-yellow-200 relative overflow-hidden"
            >
              <FontAwesomeIcon className="w-4 h-4 mr-3" icon={faArrowRight} />
              {t("Start consultation")}
              <span className="absolute top-0 right-0 bottom-0 rounded-3xl left-0 bg-yellow-200 dark:bg-yellow-200 opacity-0 transform origin-center scale-0 animate-ping"></span>
            </PrimaryButton>
          </Link>

          <Link className="w-full" to={`/client/consultation/${client?.id}`}>
            <PrimaryButton className="flex m-1 w-full justify-center">
              <FontAwesomeIcon className="w-4 h-4 mr-3" icon={faPlus} />
              {t("Add new counsultation")}
            </PrimaryButton>
          </Link>
          <Link className="w-full" to={`/client/placement/${client?.id}`}>
            <PrimaryButton className="flex m-1 w-full justify-center">
              <FontAwesomeIcon className="w-4 h-4 mr-3" icon={faPlus} />
              {t("Add result placement test")}
            </PrimaryButton>
          </Link>
          <Link className="w-full" to={`/client/program/${client?.id}`}>
            <PrimaryButton className="flex m-1 w-full justify-center">
              <FontAwesomeIcon className="w-4 h-4 mr-3" icon={faPlus} />
              {t("Add client program")}
            </PrimaryButton>
          </Link>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
          <Card className={`flex flex-col items-center md:items-start`}>
            <p>{t("The pupose of the consultation") + ":"}</p>
            <p className="text-3xl font-bold text-center md:text-left">
              {loadingClient ? (
                <Loader />
              ) : (
                <>
                  {t(
                    "Service_" +
                      getServiceLabel(
                        client?.user?.visits[client?.user?.visits.length - 1]
                          ?.service
                      )
                  )}
                  <br></br>
                  <span className="text-xs italic text-blue-400">
                    {moment(
                      client?.user?.visits[client?.user?.visits.length - 1]
                        ?.created_at
                    ).format("LLLL")}
                  </span>
                </>
              )}
            </p>
          </Card>
          <Card className={`flex flex-col items-center md:items-start`}>
            <p>{t("Last handled by") + ":"}</p>
            <p className="text-3xl font-bold text-center md:text-left">
              {loadingClient ? (
                <Loader />
              ) : client?.handled_by.length !== 0 ? (
                <>
                  {client?.handled_by.slice(-1)[0]?.user?.name}
                  <br></br>
                  <span className="text-xs italic text-blue-400">
                    {moment(client?.handled_by.slice(-1)[0]?.created_at).format(
                      "LLLL"
                    )}
                  </span>
                </>
              ) : (
                "-"
              )}
            </p>
          </Card>
        </div>

        {client && <MetadataClient client={client} />}

        <Card>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <ListWithIcon icon={faMapPin}>
              {t("Birth Place")}:{" "}
              <span className="font-bold">
                {loadingClient ? <Loader /> : client?.user?.birthplace}
              </span>
            </ListWithIcon>
            <ListWithIcon icon={faUser}>
              {t("Age")}:{" "}
              <span className="font-bold">
                {loadingClient ? (
                  <Loader />
                ) : (
                  Math.abs(
                    moment(client?.user?.birthday).diff(moment(), "years")
                  ) +
                  " " +
                  t("Y.O")
                )}
              </span>
            </ListWithIcon>
            <ListWithIcon icon={faCalendar}>
              {t("Date of birth")}:{" "}
              <span className="font-bold">
                {loadingClient ? (
                  <Loader />
                ) : (
                  moment(client?.user?.birthday).format("LL")
                )}
              </span>
            </ListWithIcon>
            <ListWithIcon icon={faHeart}>
              {t("Marital Status")}:{" "}
              <span className="font-bold">
                {loadingClient ? (
                  <Loader />
                ) : (
                  t(
                    "MarriageStatus_" +
                      getMarriageStatusLabel(client?.user?.marriage_status)
                  )
                )}
              </span>
            </ListWithIcon>
            <ListWithIcon icon={faBusinessTime}>
              {t("Current Job")}:{" "}
              <span className="font-bold">
                {loadingClient ? (
                  <Loader />
                ) : (
                  t("Work_" + getJobLabel(client?.work))
                )}
              </span>
            </ListWithIcon>
            <ListWithIcon icon={faHome}>
              {t("Address")}:{" "}
              <span className="font-bold">
                {loadingClient ? <Loader /> : client?.user.address}
              </span>
            </ListWithIcon>
            <ListWithIcon icon={faFileCircleCheck}>
              {t("Have You Applied for a Visa Before?")}{" "}
              <span className="font-bold">
                {loadingClient ? (
                  <Loader />
                ) : (
                  t(
                    "HasAppliedVisa_" +
                      getVisaStatusLabel(client?.has_applied_visa_before)
                  )
                )}
              </span>
            </ListWithIcon>
            <ListWithIcon icon={faLightbulb}>
              {t("How Did You Hear About Us?")}{" "}
              <span className="font-bold">
                {loadingClient ? (
                  <Loader />
                ) : (
                  t("KnowFrom_" + getKnowFromLabel(client?.know_from))
                )}
              </span>
            </ListWithIcon>
          </div>
        </Card>
        <div>
          {loadingClient ? (
            <Loader />
          ) : (
            <ServiceProgress user_id={client?.user?.id} />
          )}
        </div>
      </BeginPage>
    </Authentication>
  );
}
