import Dropdown from "../../../Components/Dropdown";
import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../Components/PrimaryButton";
import TableHeader from "../../../Components/TableHeader";
import TableBody from "../../../Components/TableBody";
import moment from "moment";
import { Link } from "react-router-dom";
import { useProgram } from "../../../Utils/Context/ProgramContext";
import { useTranslation } from "react-i18next";
import { Pagination } from "flowbite-react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Search from "../../../Components/Search";
import Loader from "../../../Components/Loader";
import PopUpModal from "../../../Components/PopUpModal";

export default function TableProgram() {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const { fetchAll, programs, isLoading, remove } = useProgram();
  const [openModal, setOpenModal] = useState(false);
  const [willBeDestroyId, setWillBeDestroyId] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    fetchAll({ page: page, keyword: keyword });
  }, [page, keyword]);

  const removeConfirmation = (id) => {
    setOpenModal(!openModal);
    setWillBeDestroyId(id);
  };

  const destroy = () => {
    remove(willBeDestroyId).then(() => {
      fetchAll({ page: page, keyword: keyword });
      setWillBeDestroyId(null);
      setOpenModal(false);
    });
  };

  const heads = [t("No."), t("Name"), t("Action")];

  return isLoading ? (
    <div className="w-full flex justify-center">
      <Loader />
    </div>
  ) : (
    <>
      <PopUpModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        message={
          <>
            <h1>{t("Are you sure?")}</h1>
            <p>{t("This Action cannot be undo.")}</p>
          </>
        }
        action={
          <>
            <PrimaryButton onClick={() => setOpenModal(false)}>
              {t("Cancel")}
            </PrimaryButton>
            <PrimaryButton onClick={() => destroy()}>{t("Yes")}</PrimaryButton>
          </>
        }
      />

      <div className="overflow-x-auto">
        <p className="font-bold text-lg text-center md:text-left my-3">
          Total: {programs?.total}
        </p>
        <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
          <Search onSubmit={(e) => setKeyword(e.keyword)} />
          <Link to={`/program/create`}>
            <PrimaryButton className="my-5 w-full md:w-fit">
              <p className="w-full text-center">
                <FontAwesomeIcon className="mr-3" icon={faPlus} />
                {t("Add new program")}
              </p>
            </PrimaryButton>
          </Link>
        </div>
        <table className="w-full">
          <thead>
            <tr className="text-center font-bold text-xl">
              {heads.map((item, index) => (
                <TableHeader key={index} title={item} />
              ))}
            </tr>
          </thead>
          <tbody>
            {programs !== null &&
              programs.data.map((item, index) => {
                return (
                  <tr key={index} className={"border-b"}>
                    <TableBody
                      className={"text-center"}
                      children={programs.from + index}
                    />
                    <TableBody>
                      <Link
                        className="hover:bg-slate-400 truncate px-5 py-2 transition-all duration-500 rounded-full"
                        to={`/program/create?id=${item.id}`}
                      >
                        {item.name}
                      </Link>
                    </TableBody>
                    <TableBody
                      className={
                        "text-center p-0 grid cursor-pointer grid-cols-1 md:grid-cols-1 gap-2"
                      }
                    >
                      <Dropdown>
                        <Dropdown.Trigger>...</Dropdown.Trigger>
                        <Dropdown.Content>
                          <PrimaryButton
                            onClick={() => removeConfirmation(item.id)}
                            className="flex m-1 w-fit justify-center"
                          >
                            Delete
                          </PrimaryButton>
                        </Dropdown.Content>
                      </Dropdown>
                    </TableBody>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="flex justify-center md:justify-end w-full p-5">
          <Pagination
            layout="navigation"
            currentPage={programs.current_page}
            totalPages={programs.last_page}
            onPageChange={(e) => setPage(e)}
          />
        </div>
      </div>
    </>
  );
}
