const Carrency = {
  USD: "USD",
  AUD: "AUD",
  SGD: "SGD",
  CHF: "CHF",
  CAD: "CAD",
};

const getLabel = (x) => {
  switch (x) {
    case Carrency.USD:
      return "USD";
    case Carrency.AUD:
      return "AUD";
    case Carrency.SGD:
      return "SGD";
    case Carrency.CHF:
      return "CHF";
    case Carrency.CAD:
      return "CAD";
    default:
      return "Unknown";
  }
};

export { getLabel };

export default Carrency;
