const MarriageStatus = {
  Single: 0,
  Married: 1,
  Divorced: 2,
};

const getMarriageStatusLabel = (marriageStatusValue) => {
  switch (marriageStatusValue) {
    case MarriageStatus.Single:
      return "Single";
    case MarriageStatus.Married:
      return "Married";
    case MarriageStatus.Divorced:
      return "Divorced";
    default:
      return "Unknown";
  }
};

export { getMarriageStatusLabel };

export default MarriageStatus;
