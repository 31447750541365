import Header from "../../Components/Header";
import Authenticated from "../../Layouts/Authentication";
import React from "react";
import TableClient from "./Partials/TableClient";
import ClientCreate from "./Create";
import ClientConsultation from "./Consultation";
import ClientShow from "./Show";
import ClientCheck from "./Check";
import ClientProgram from "./Program";
import ClientPlacementTest from "./Placement";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import BeginPage from "../../Components/BeginPage";

export {
  ClientConsultation,
  ClientCreate,
  ClientShow,
  ClientPlacementTest,
  ClientCheck,
  ClientProgram,
};

export default function Client() {
  const { t } = useTranslation();

  return (
    <Authenticated header={<Header>{t("Clients")}</Header>}>
      <Head title={t("Client")} />
      <BeginPage>
        <TableClient />
      </BeginPage>
    </Authenticated>
  );
}
