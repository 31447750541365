import React, { useEffect, useState } from "react";
import Authentication from "../../../Layouts/Authentication";
import Header from "../../../Components/Header";
import Head from "../../../Components/Head";
import BeginPage from "../../../Components/BeginPage";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../Components/PrimaryButton";

import { useInvoice } from "../../../Utils/Context/InvoiceContext";
import { useNavigate } from "react-router-dom";
import ClientSection from "./Partials/ClientSelection";
import toast, { LoaderIcon } from "react-hot-toast";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { store: invoiceStore, isSuccess } = useInvoice();
  const [isLoading, setIsLoading] = useState(false);

  const [clients, setClients] = useState([{ client_id: null, details: [] }]);

  const storeInvoiceExecute = async (e) => {
    e.preventDefault();
    let data = {
      details: clients,
    };
    setIsLoading(true);
    await invoiceStore(data);
    setIsLoading(false);
  };

  useEffect(() => {
    function successHandle() {
      navigate(-1);
      toast.success(t("Invoice added successfully"));
    }
    isSuccess && successHandle();
  }, [isSuccess]);

  const addAnotherItem = (clientIndex, data) => {
    const newItems = clients[clientIndex].details.concat(data);

    setClients((prevClients) =>
      prevClients.map((client, index) =>
        index === clientIndex ? { ...client, details: newItems } : client
      )
    );
  };

  const removeClient = (clientIndex) => {
    setClients((prevClients) =>
      prevClients.filter((_, index) => index !== clientIndex)
    );
  };

  const deleteItem = (clientIndex, itemIndex) => {
    setClients((prevClients) =>
      prevClients.map((client, index) =>
        index === clientIndex
          ? {
              ...client,
              details: client.details.filter((_, i) => i !== itemIndex),
            }
          : client
      )
    );
  };

  const addAnotherClient = () => {
    setClients([...clients, { client_id: null, details: [] }]);
  };

  return (
    <Authentication header={<Header>{t("Create Invoice")}</Header>}>
      <Head title={t("Create Invoice")} />
      <BeginPage>
        {clients.map((client, index) => (
          <ClientSection
            key={index}
            currentIndex={index}
            details={client.details}
            onClientSelected={(clientSelected) => {
              setClients((prevClients) =>
                prevClients.map((c, i) =>
                  i === index
                    ? { ...c, client_id: clientSelected, details: [] }
                    : c
                )
              );
            }}
            onItemAdded={(data) => addAnotherItem(index, data)}
            onItemDeleted={(itemIndex) => deleteItem(index, itemIndex)}
            onClientRemoved={(clientIndex) => removeClient(clientIndex)}
          />
        ))}
        <div className="w-full flex justify-start">
          <PrimaryButton className="my-5" onClick={addAnotherClient}>
            {t("Add Another Client")}
          </PrimaryButton>
        </div>
        {isLoading && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-100 opacity-50 z-50 flex items-center justify-center">
            <LoaderIcon />
          </div>
        )}

        {isLoading === false && clients.length > 0 && (
          <div className="w-full flex justify-center">
            <PrimaryButton className="my-5" onClick={storeInvoiceExecute}>
              {t("Create Invoice")}
            </PrimaryButton>
          </div>
        )}
      </BeginPage>
    </Authentication>
  );
}
