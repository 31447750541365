import React from "react";

export default function Title({ children, className, size }) {
  let textSize;
  if (size === "sm") {
    textSize = "text-sm md:text-lg";
  } else if (size === "lg") {
    textSize = "text-lg md:text-2xl";
  } else if (size === "md") {
    textSize = "text-md md:text-xl";
  } else {
    textSize = "text-xl md:text-3xl";
  }
  return (
    <p
      className={` ${textSize}text-primary dark:text-white font-bold py-5 ${className}`}
    >
      <span className="mr-5 bg-primary dark:bg-white rounded-full p-1"></span>
      {children}
    </p>
  );
}
