import React, { useEffect, useState } from "react";
import Unauthentication from "../../Layouts/Unauthentication";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useAuth } from "../../Utils/Context/AuthContext";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { LoadingLottie, LogoPng, SuccessLottie } from "../../Assets";
import InputLabel from "../../Components/InputLabel";
import TextInput from "../../Components/TextInput";
import PrimaryButton from "../../Components/PrimaryButton";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { login, isError, isSuccess, isLoading, auth } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccess) {
      const timeoutId = setTimeout(() => successHandle(auth), 3000);
      return () => clearTimeout(timeoutId);
    }

    if (isError) {
      errorHandle();
    }
  }, [isSuccess, isError]);

  const reset = () => {
    setEmail("");
    setPassword("");
  };

  const successHandle = (r) => {
    sessionStorage.setItem("token", r.data.token);
    sessionStorage.setItem("user", JSON.stringify(r.data.user));
    sessionStorage.setItem("roles", JSON.stringify(r.data.user?.roles));
    reset();
    let from = location.state?.from || "/";
    navigate(from);
  };

  const errorHandle = () => setPassword("");

  const attemptLogin = async (e) => {
    e.preventDefault();
    await login({ email, password });
  };

  return (
    <Unauthentication>
      <Head title="Login" />
      <div className="transition-all duration-500 w-4/6">
        <Toaster />
        <form onSubmit={attemptLogin}>
          <div className="bg-white aspect-auto flex flex-col justify-center items-center p-5 md:p-10 dark:bg-slate-800 shadow-lg rounded-3xl">
            <div className="w-full">
              <InputLabel htmlFor="email" value="Email" />
              <TextInput
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full"
                autoComplete="username"
                isFocused={true}
              />
            </div>

            <div className="mt-4 w-full">
              <InputLabel htmlFor="password" value="Password" />
              <TextInput
                id="password"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full"
                autoComplete="current-password"
              />
            </div>
          </div>

          <div className="flex items-center justify-center mt-4">
            {isLoading ? (
              <Lottie className="w-16 h-auto" animationData={LoadingLottie} />
            ) : isSuccess ? (
              <Lottie className="w-16 h-auto" animationData={SuccessLottie} />
            ) : (
              <PrimaryButton
                type="submit"
                className="ml-4"
                disabled={isLoading}
              >
                Login
              </PrimaryButton>
            )}
          </div>
        </form>

        <div className="flex flex-col items-center justify-center mt-4 gap-3">
          {/* <Link
            to={`/register`}
            className="underline text-xs text-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
          >
            {t("Create Konsultan Visa account here!")}
          </Link> */}

          {/* <Link
            to={"/forgot-password"}
            className="underline text-xs text-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
          >
            {t("Forgot your password?")}
          </Link> */}

          <img
            src={LogoPng}
            alt="Logo"
            className="block md:hidden w-32 md:h-auto"
          />
        </div>
      </div>
    </Unauthentication>
  );
}
