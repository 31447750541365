import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "flowbite-react";

export default function PopUpModal({ isOpen, message, icon, onClose, action }) {
  return (
    <Modal
      show={isOpen}
      size="md"
      popup
      onClose={onClose} // Use the provided onClose callback
    >
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <FontAwesomeIcon
            icon={icon}
            className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
          />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {message}
          </h3>
          <div className="flex justify-center gap-4">{action}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
