const Job = {
  Student: 1,
  InformationTechnology: 2,
  Healthcare: 3,
  Finance: 4,
  Education: 5,
  Engineering: 6,
  Hospitality: 7,
  Entrepreneur: 8,
  Unemployed: -1,
  Other: 0,
};

const getJobLabel = (jobValue) => {
  switch (jobValue) {
    case Job.Student:
      return "Student";
    case Job.InformationTechnology:
      return "InformationTechnology";
    case Job.Healthcare:
      return "Healthcare";
    case Job.Finance:
      return "Finance";
    case Job.Education:
      return "Education";
    case Job.Engineering:
      return "Engineering";
    case Job.Hospitality:
      return "Hospitality";
    case Job.Entrepreneur:
      return "Entrepreneur";
    case Job.Unemployed:
      return "Unemployed";
    case Job.Other:
      return "Other";
    default:
      return "Unknown";
  }
};

export { getJobLabel };

export default Job;
