import Header from "../../Components/Header";
import Authenticated from "../../Layouts/Authentication";
import React from "react";
import TableUser from "./Partials/TableUser";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import UserCreate from "./Create";
import UserShow from "./Show";
import UserEdit from "./Edit";
import BeginPage from "../../Components/BeginPage";

export { UserCreate, UserShow, UserEdit };

export default function User() {
  const { t } = useTranslation();

  return (
    <Authenticated header={<Header>{t("Admin")}</Header>}>
      <Head title={t("Admin")} />
      <BeginPage>
        <TableUser />
      </BeginPage>
    </Authenticated>
  );
}
