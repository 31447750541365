import React, { useEffect, useState } from "react";
import Authentication from "../../Layouts/Authentication";
import Header from "../../Components/Header";
import PrimaryButton from "../../Components/PrimaryButton";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useSchool } from "../../Utils/Context/SchoolContext";
import toast from "react-hot-toast";

export default function Create() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const school_id = queryParams.get("id");

  const { t } = useTranslation();
  const { store, isSuccess, isSuccessUpdate, fetchById, school, update } =
    useSchool();
  const navigate = useNavigate();

  const [name, setName] = useState(school?.name ?? null);

  useEffect(() => {
    async function getData() {
      await fetchById(school_id);
    }
    if (school_id) getData();
  }, [school_id]);

  useEffect(() => {
    if (school) {
      setName(school.name ?? "");
    }
  }, [school]);

  const storeExe = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("is_eligible_for_apply", 0);

    if (school) {
      await update(school_id, formData);
    } else {
      await store(formData);
    }
  };

  useEffect(() => {
    function successHandle() {
      navigate(-1);
      toast.success(t("school added successfully"));
    }
    isSuccess && successHandle();
  }, [isSuccess]);
  useEffect(() => {
    function successHandle() {
      navigate(-1);
      toast.success(t("school has been updated"));
    }
    isSuccessUpdate && successHandle();
  }, [isSuccessUpdate]);

  return (
    <Authentication header={<Header>{t("Add new school")}</Header>}>
      <Head title={t("Add new school")} />
      <div className="p-10 w-screen md:w-auto dark:text-slate-200">
        <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
          <form
            className="w-full"
            onSubmit={storeExe}
            encType="multipart/form-data"
          >
            <div className="grid grid-cols-1 md:grid-cols-1 gap-5">
              <div>
                <InputLabel>{t("School Name")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <PrimaryButton className="my-5 w-full md:w-fit">
                <p className="w-full text-center">Submit</p>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </Authentication>
  );
}
