import React, { useEffect, useState } from "react";
import Authentication from "../../Layouts/Authentication";
import Header from "../../Components/Header";
import PrimaryButton from "../../Components/PrimaryButton";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useTemplate } from "../../Utils/Context/TemplateContext";
import toast from "react-hot-toast";
import MDEditor from "@uiw/react-md-editor";

export default function Create() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const template_id = queryParams.get("id");

  const { t } = useTranslation();
  const { store, isSuccess, isSuccessUpdate, fetchById, template, update } =
    useTemplate();
  const navigate = useNavigate();

  const [name, setName] = useState(template?.name ?? "");
  const [content, setContent] = useState(template?.content ?? "");

  useEffect(() => {
    async function getData() {
      await fetchById(template_id);
    }
    if (template_id) getData();
  }, [template_id]);

  useEffect(() => {
    if (template) {
      setName(template.name ?? "");
      setContent(template.content ?? "");
    }
  }, [template]);

  const storeExe = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("content", content);

    if (template) {
      await update(template_id, formData);
    } else {
      await store(formData);
    }
  };

  useEffect(() => {
    function successHandle() {
      navigate(-1);
      toast.success(t("template added successfully"));
    }
    isSuccess && successHandle();
  }, [isSuccess]);
  useEffect(() => {
    function successHandle() {
      navigate(-1);
      toast.success(t("template has been updated"));
    }
    isSuccessUpdate && successHandle();
  }, [isSuccessUpdate]);

  return (
    <Authentication header={<Header>{t("Add new template")}</Header>}>
      <Head title={t("Add new template")} />
      <div className="p-10 w-screen md:w-auto dark:text-slate-200">
        <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
          <form
            className="w-full"
            onSubmit={storeExe}
            encType="multipart/form-data"
          >
            <div className="grid grid-cols-1 md:grid-cols-1 gap-5">
              <div>
                <InputLabel>{t("template Name")}</InputLabel>
                <TextInput
                  className="w-full"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <InputLabel>{t("template content")}</InputLabel>
                <MDEditor
                  value={content}
                  onChange={setContent}
                  height={700}
                  className="rounded-3xl p-5"
                />
              </div>
            </div>
            <div className="flex justify-end">
              <PrimaryButton className="my-5 w-full md:w-fit">
                <p className="w-full text-center">Submit</p>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </Authentication>
  );
}
