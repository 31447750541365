import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";

export default function TimelineItem({
  content,
  title,
  isLatest = null,
  date,
  body,
  icon = faCalendarAlt,
  isActive = true,
}) {
  return (
    <li className={`mb-10 ml-6 ${!isActive && "text-gray-300"}`}>
      <span
        className={`absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 ${
          isActive
            ? "dark:bg-blue-900 bg-blue-100"
            : "bg-gray-100 dark:bg-gray-100"
        }`}
      >
        <FontAwesomeIcon icon={icon} className="w-3 h-3" />
      </span>
      <h3
        className={`flex items-center mb-1 text-lg font-semibold ${
          isActive
            ? "text-gray-900 dark:text-white"
            : "text-gray-300 dark:text-slate-600"
        } `}
      >
        {title}
        {isLatest && (
          <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ml-3">
            Latest
          </span>
        )}
      </h3>
      <time
        className={`block mb-2 text-sm font-normal leading-none ${
          isActive
            ? "text-gray-400 dark:text-gray-500"
            : "text-gray-100 dark:text-gray-8   00"
        }`}
      >
        {date && moment(date).format("LLLL")}
      </time>
      <p
        className={`mb-4 text-base font-normal ${
          isActive
            ? "text-gray-400 dark:text-gray-400"
            : "text-gray-100 dark:text-gray-800"
        }`}
      >
        {body}
      </p>
      {content ?? content}
    </li>
  );
}
