import Header from "../../Components/Header";
import Authenticated from "../../Layouts/Authentication";
import React from "react";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import BeginPage from "../../Components/BeginPage";
import TableTemplate from "./Partials/TableTemplate";
import TemplateCreate from "./Create";

export { TemplateCreate };

export default function Template() {
  const { t } = useTranslation();
  return (
    <Authenticated header={<Header>{t("Template")}</Header>}>
      <Head title={t("Template")} />
      <BeginPage>
        <TableTemplate />
      </BeginPage>
    </Authenticated>
  );
}
