import React, { useEffect, useState } from "react";
import Authenticated from "../../Layouts/Authentication";
import Head from "../../Components/Head";
import Header from "../../Components/Header";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import { useTranslation } from "react-i18next";
import InputError from "../../Components/InputError";
import { useAuth } from "../../Utils/Context/AuthContext";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import PasswordGenerator from "../../Utils/Tools/PasswordGenerator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faShuffle } from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "../../Utils/Tools/CopyToClipboard";
import { useNavigate } from "react-router-dom";

export default function Create() {
  const { register, auth, isLoading, errorMessage, isSuccess, isError } =
    useAuth();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmatiom, setPassword_confirmation] = useState("");
  const navigate = useNavigate();

  const attemptRegister = async (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append("user[name]", name);
    form.append("user[email]", email);
    form.append("user[password]", password);
    form.append("user[password_confirmation]", password_confirmatiom);
    await register(form);
  };

  const successHandle = () => {
    setName("");
    setEmail("");
    setPassword("");
    setPassword_confirmation("");
    navigate("/user/" + auth.data.id);
  };

  const errorHandle = () => {
    setPassword("");
    setPassword_confirmation("");
  };

  const generatePassword = () => {
    let newPassword = PasswordGenerator();
    setPassword(newPassword);
    setPassword_confirmation(newPassword);
  };

  useEffect(() => {
    isSuccess && successHandle();
    isError && errorHandle();
  }, [isSuccess, isError]);

  return (
    <Authenticated header={<Header>{t("Add New Admin")}</Header>}>
      <Head title={t("Admin")} />
      <div className="p-10 w-screen md:w-auto dark:text-slate-200">
        <form onSubmit={attemptRegister} className="grid gap-3">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div>
              <InputLabel value={t("Name")} />
              <TextInput
                className="w-full"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <InputError
                message={errorMessage?.["user.name"]?.[0] ?? ""}
                className="mt-2"
              />
            </div>
            <div>
              <InputLabel value={t("Email")} />
              <TextInput
                type="email"
                className="w-full"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputError
                message={errorMessage?.["user.email"]?.[0] ?? ""}
                className="mt-2"
              />
            </div>
          </div>
          <div className="flex gap-3 justify-center">
            <SecondaryButton
              className="w-full md:w-1/4 flex justify-center"
              onClick={() => generatePassword()}
              type="button"
              disabled={isLoading}
            >
              <FontAwesomeIcon className="mr-3 text-xl" icon={faShuffle} />
              {t("Generate password")}
            </SecondaryButton>
            <SecondaryButton
              className="w-full md:w-1/4 flex justify-center"
              onClick={() => CopyToClipboard(password)}
              type="button"
              disabled={isLoading}
            >
              <FontAwesomeIcon className="mr-3 text-xl" icon={faCopy} />
              {t("Copy password")}
            </SecondaryButton>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div>
              <InputLabel value={t("Password")} />
              <TextInput
                type="password"
                className="w-full"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputError
                message={errorMessage?.["user.password"]?.[0] ?? ""}
                className="mt-2"
              />
            </div>
            <div>
              <InputLabel value={t("Password Confirmation")} />
              <TextInput
                type="password"
                className="w-full"
                value={password_confirmatiom}
                onChange={(e) => setPassword_confirmation(e.target.value)}
              />
              <InputError
                message={
                  errorMessage?.["user.password_confirmation"]?.[0] ?? ""
                }
                className="mt-2"
              />
            </div>
          </div>
          <div className="flex justify-center gap-3">
            <PrimaryButton type="submit" disabled={isLoading}>
              Submit
            </PrimaryButton>
          </div>
        </form>
      </div>
    </Authenticated>
  );
}
