import React, { useEffect, useState } from "react";
import Card from "../../Components/Card";
import Title from "../../Components/Title";
import TextInput from "../../Components/TextInput";
import PrimaryButton from "../../Components/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Utils/Context/AuthContext";

export default function Password() {
  const { t } = useTranslation();
  const { updatePassword, isSuccess, isLoading } = useAuth();
  const [current_password, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");

  const attemptUpdate = (e) => {
    e.preventDefault();
    updatePassword({
      current_password: current_password,
      password: password,
      password_confirmation: password_confirmation,
    });
  };

  useEffect(() => {
    isSuccess && reset();
  }, [isSuccess]);

  const reset = () => {
    setCurrentPassword("");
    setPassword("");
    setPassword_confirmation("");
  };

  return (
    <form onSubmit={attemptUpdate}>
      <Card className={`flex flex-col`}>
        <Title>{t("Password")}</Title>
        <div className={`grid grid-cols-1 md:grid-cols-2 gap-3`}>
          <TextInput
            type="password"
            value={current_password}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="w-full"
            placeholder={t("Old Password")}
          />
          <TextInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full"
            placeholder={t("New Password")}
          />
          <TextInput
            type="password"
            value={password_confirmation}
            onChange={(e) => setPassword_confirmation(e.target.value)}
            className="w-full"
            placeholder={t("New Confirmation Password")}
          />
        </div>
        <div className="flex justify-center items-center">
          <PrimaryButton disabled={isLoading} type="submit" className="w-fit">
            {t("Update")}
          </PrimaryButton>
        </div>
      </Card>
    </form>
  );
}
