import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Alert({
  icon,
  title,
  subTitle,
  isSuccess = false,
  isError = false,
}) {
  return (
    <div class="space-y-5">
      <div
        class={`bg-teal-50 border-t-2 ${isSuccess && "border-teal-500"} ${
          isError && "border-red-500"
        } rounded-lg p-4 dark:bg-teal-800/30`}
        role="alert"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <span class="inline-flex justify-center items-center size-8 rounded-full border-4 border-teal-100 bg-teal-200 text-teal-800 dark:border-teal-900 dark:bg-teal-800 dark:text-teal-400">
              {icon}
            </span>
          </div>
          <div class="ms-3">
            <h3 class="text-gray-800 font-semibold dark:text-white">{title}</h3>
            <p class="text-sm text-gray-700 dark:text-neutral-400">
              {subTitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
