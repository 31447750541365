import React, { useEffect } from "react";
import Authentication from "../Layouts/Authentication";
import Header from "../Components/Header";
import BeginPage from "../Components/BeginPage";
import Head from "../Components/Head";
import { useTranslation } from "react-i18next";
import { Carousel } from "flowbite-react";
import { supabase } from "../Utils/Network/Supabase";
import { useState } from "react";

import Loader from "../Components/Loader";
import { PUBLIC_URL_SUPABASE } from "../Utils/Constant";
import TableClient from "./Client/Partials/TableClient";
import toast from "react-hot-toast";
import { Error } from "../Utils/Message";

export default function Home() {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function go() {
      let { data, error } = await supabase.storage
        .from("assets KV management system")
        .list("banners");
      data && setImages(data);
      error && toast.error(Error);
    }
    go();
  }, []);

  return (
    <Authentication header={<Header>{t("Dashboard")}</Header>}>
      <Head title={t("Dashboard")} />
      <BeginPage>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="aspect-video">
            <Carousel className="rounded-3xl" slideInterval={5000}>
              {images.length > 0 ? (
                images.map((item, index) => {
                  return (
                    <img
                      key={index}
                      className="rounded-3xl"
                      src={PUBLIC_URL_SUPABASE + "banners/" + item.name}
                      alt={item.name}
                    />
                  );
                })
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  <Loader />
                </div>
              )}
            </Carousel>
          </div>
          <div>
            <div>
              <p className="text-center text-xl font-bold">
                {t("Today's Visit")}
              </p>
            </div>
            <TableClient isToday={true} />
          </div>
        </div>
      </BeginPage>
    </Authentication>
  );
}
