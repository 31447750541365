export const getRoles = () => {
  let roles = [];
  if (typeof window !== "undefined") {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      roles = user.roles;
    }
  }
  return roles;
};
