import React, { useState } from "react";
import PrimaryButton from "../Components/PrimaryButton";
import ConsultationPage from "../Components/ConsultationPage";
import { useTranslation } from "react-i18next";
import PlacementTestPage from "../Components/PlacementTestPage";
import ProgramList from "../Components/ProgramList";

export default function ServiceProgress({ user_id }) {
  const [activeButton, setActiveButton] = useState("consultation");
  const { t } = useTranslation();

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  let content;
  switch (activeButton) {
    case "consultation":
      content = <ConsultationPage user_id={user_id} />;
      break;
    case "placement test":
      content = <PlacementTestPage user_id={user_id} />;
      break;
    case "program":
      content = <ProgramList user_id={user_id} />;
      break;
    default:
      content = null;
  }
  return (
    <>
      <div className="p-5 w-full md:w-auto">
        <div className="flex gap-5 overflow-x-auto">
          <PrimaryButton
            onClick={() => handleButtonClick("consultation")}
            className={
              activeButton !== "consultation"
                ? "bg-slate-400 dark:bg-slate-600"
                : ""
            }
          >
            {t("Consultations")}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleButtonClick("placement test")}
            className={
              activeButton !== "placement test"
                ? "bg-slate-400 dark:bg-slate-600"
                : ""
            }
          >
            {t("Placement Tests")}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleButtonClick("program")}
            className={
              activeButton !== "program" ? "bg-slate-400 dark:bg-slate-600" : ""
            }
          >
            {t("Programs")}
          </PrimaryButton>
        </div>
      </div>

      {content}
    </>
  );
}
