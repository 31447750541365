import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const usePlacementTest = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessGet, setIsSuccessGet] = useState(false);
  const [placementTest, setplacementTest] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleError = (e) => {
    setIsError(true);
    setErrorMessage(e.response?.data?.errors);
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };
  const handleFinish = () => {
    setIsLoading(false);
  };
  const handleInitial = () => {
    setIsLoading(true);
  };

  const store = async (data) => {
    handleInitial();
    await instanceRequest("post", "placement_test", undefined, data)
      .then((r) => {
        setIsSuccess(true);
        setplacementTest(r);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const update = async (id, data) => {
    handleInitial();
    await instanceRequest("put", `placement_test/${id}`, undefined, data)
      .then((r) => {
        setIsSuccess(true);
        setplacementTest(r);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const fetchById = async (id) => {
    handleInitial();
    const config = {
      params: {
        include: ["checkedBy", "handledBy"],
      },
    };
    await instanceRequest("get", `placement_test/${id}`, config, undefined)
      .then((r) => {
        setIsSuccessGet(true);
        setplacementTest(r.data);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const fetchByUserId = async (id) => {
    handleInitial();
    const config = {
      params: {
        "filter[user.id]": id,
        include: ["checkedBy", "handledBy"],
      },
    };
    await instanceRequest("get", `placement_test`, config, undefined)
      .then((r) => {
        setIsSuccess(true);
        setplacementTest(r.data);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  return {
    isLoading,
    isError,
    isSuccess,
    isSuccessGet,
    errorMessage,
    placementTest,
    store,
    update,
    fetchById,
    fetchByUserId,
  };
};
