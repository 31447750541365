import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../../Components/PrimaryButton";
import TableHeader from "../../../../Components/TableHeader";
import TableBody from "../../../../Components/TableBody";
import moment from "moment";
import Loader from "../../../../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Search from "../../../../Components/Search";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Pagination } from "flowbite-react";
import { useInvoice } from "../../../../Utils/Context/InvoiceContext";
import StatusInvoice, { getLabel } from "../../../../Utils/Enums/StatusInvoice";
import { LoaderIcon } from "react-hot-toast";

export default function TableInvoice() {
  const { t } = useTranslation();
  const { invoices, fetchAll, isLoading } = useInvoice();
  const heads = [
    t("No."),
    t("Number of Invoice"),
    t("Status"),
    t("Name(s)"),
    t("Created at"),
    // t("Action"),
  ];
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState(1);

  useEffect(() => {
    fetchAll({ page: page, keyword: keyword, status: status });
  }, [page, keyword, status]);

  return isLoading ? (
    <div className="w-full flex justify-center">
      <LoaderIcon />
    </div>
  ) : (
    <>
      <div className="grid md:flex grid-cols-1 md:grid-cols-2 justify-between">
        <Search onSubmit={(e) => setKeyword(e.keyword)} />
        <Link to={`/invoice/create`}>
          <PrimaryButton className="my-5 w-full md:w-fit">
            <p className="w-full text-center">
              <FontAwesomeIcon className="mr-3" icon={faPlus} />
              {t("Add new invoice")}
            </p>
          </PrimaryButton>
        </Link>
      </div>
      <div className="overflow-x-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <p className="font-bold text-lg text-center md:text-left my-3">
            Total: {invoices?.total}
          </p>
          <div className="flex justify-end items-center">
            <Button.Group>
              {Object.keys(StatusInvoice).map((key) => (
                <Button
                  key={key}
                  color={StatusInvoice[key] === status ? "info" : "gray"}
                  onClick={() => setStatus(StatusInvoice[key])}
                >
                  {getLabel(StatusInvoice[key])}
                </Button>
              ))}
            </Button.Group>
          </div>
        </div>
        <table className="w-full mb-10">
          <thead>
            <tr className="text-center font-bold text-xl">
              {heads.map((item, index) => (
                <TableHeader key={index} title={item} />
              ))}
            </tr>
          </thead>
          <tbody>
            {invoices?.data.length === 0 ? (
              <tr>
                <td colSpan="5" className="text-center">
                  No data
                </td>
              </tr>
            ) : (
              invoices?.data.map((item, index) => {
                const formattedDate = moment(item.created_at).calendar(null, {
                  sameDay: "[Today]",
                  sameElse: "LLL",
                });

                // Extract client names
                const clientNames = item.invoice_clients.map(
                  (client) => client.client_name
                );

                // Display maximum of 2 client names
                let displayClientNames = "";
                if (clientNames.length > 2) {
                  displayClientNames = `${clientNames
                    .slice(0, 2)
                    .join(", ")} and ${clientNames.length - 2} other`;
                } else {
                  displayClientNames = clientNames.join(", ");
                }

                // Get status label with dynamic background color
                const statusLabel = (
                  <div
                    className={`p-1 rounded-full text-black text-center ${
                      item.status === StatusInvoice.UNCONFIRMED
                        ? "bg-red-300"
                        : item.status === StatusInvoice.UNPAID
                        ? "bg-yellow-300"
                        : item.status === StatusInvoice.PAID
                        ? "bg-green-300"
                        : item.status === StatusInvoice.VOID
                        ? "bg-slate-300"
                        : ""
                    }`}
                  >
                    {getLabel(item.status)}
                  </div>
                );

                return (
                  <tr key={index} className={"border-b"}>
                    <TableBody
                      className={"text-center"}
                      children={invoices?.from + index}
                    />
                    <TableBody>
                      <Link
                        className="hover:bg-slate-400 truncate px-5 py-2 transition-all duration-500 rounded-full"
                        to={`/invoice/${item.id}`}
                      >
                        {item.number}
                        {item.is_sent ? (
                          <span className="bg-slate-500 ml-1 px-2 rounded-lg">
                            {t("sent")}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Link>
                    </TableBody>
                    <TableBody>{statusLabel}</TableBody>
                    <TableBody>{displayClientNames}</TableBody>
                    <TableBody children={formattedDate} />
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center md:justify-end w-full p-5">
        <Pagination
          className="hidden md:block"
          layout="pagination"
          currentPage={invoices?.current_page}
          totalPages={invoices?.last_page}
          onPageChange={(e) => setPage(e)}
        />
        <Pagination
          className="block md:hidden"
          layout="navigation"
          currentPage={invoices?.current_page}
          totalPages={invoices?.last_page}
          onPageChange={(e) => setPage(e)}
        />
      </div>
    </>
  );
}
