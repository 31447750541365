import React from "react";

export function Option({ children, className, ...props }) {
  return <option {...props}>{children}</option>;
}

export default function Select({
  children,
  className,
  isError = false,
  ...props
}) {
  return (
    <select
      {...props}
      className={`w-full ${
        isError ? "border-red-300" : "border-gray-300"
      } p-3 border-2 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-300 focus:border-primary dark:focus:border-primary focus:ring-primary dark:focus:ring-primary rounded-3xl shadow-sm ${className}`}
    >
      {children}
    </select>
  );
}

Select.Option = Option;
