import moment from "moment";
import { useConsultation } from "../Utils/Context/ConsultationContext";
import Card from "./Card";
import TimelineBody from "./TimelineBody";
import TimelineItem from "./TimelineItem";
import React, { useEffect } from "react";
import Title from "./Title";
import { useTranslation } from "react-i18next";
import MDEditor from "@uiw/react-md-editor";

export default function ConsultationPage({ user_id }) {
  const { fetchAllByUserId, consultations } = useConsultation();
  const { t } = useTranslation();

  useEffect(() => {
    fetchAllByUserId(user_id);
  }, []);

  return (
    <Card>
      <TimelineBody>
        {consultations.data === undefined ? (
          <Title>{t("Please wait...")}</Title>
        ) : consultations.data.length < 1 ? (
          <Title>{t("No consultation found")}</Title>
        ) : (
          consultations.data.map((item, index) => (
            <TimelineItem
              key={index}
              title={t("Consultation with ") + item.counselor.name}
              date={moment(item.created_at).format("LLL")}
              body={
                <MDEditor.Markdown
                  className="rounded-3xl p-5"
                  source={item.note}
                />
              }
            />
          ))
        )}
      </TimelineBody>
    </Card>
  );
}
