const KnowFrom = {
  SocialMedia: 1,
  Friend: 2,
  School: 3,
  Browsing: 4,
  Events: 5,
  Others: 6,
};

const getKnowFromLabel = (knowFromValue) => {
  switch (knowFromValue) {
    case KnowFrom.SocialMedia:
      return "SocialMedia";
    case KnowFrom.Friend:
      return "Friend";
    case KnowFrom.School:
      return "School";
    case KnowFrom.Browsing:
      return "Browsing";
    case KnowFrom.Events:
      return "Events";
    case KnowFrom.Others:
      return "Others";
    default:
      return "Unknown";
  }
};

export { getKnowFromLabel };

export default KnowFrom;
