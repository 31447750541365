import { useTranslation } from "react-i18next";
import BeginPage from "../../Components/BeginPage";
import Head from "../../Components/Head";
import Header from "../../Components/Header";
import Authentication from "../../Layouts/Authentication";
import Personal from "./Personal";
import Password from "./Password";

export default function Index() {
  const { t } = useTranslation();
  return (
    <Authentication header={<Header>{t("Profile")}</Header>}>
      <Head title={t("Profile")} />
      <BeginPage>
        <Personal />
        <Password />
      </BeginPage>
    </Authentication>
  );
}
