export default function SecondaryButton({
  className = "",
  disabled,
  children,
  ...props
}) {
  return (
    <button
      {...props}
      className={
        `inline-flex items-center dark:text-slate-300 px-4 py-2 border-2 border-primary rounded-full font-semibold text-xs text-primary hover:text-white  uppercase tracking-widest hover:bg-gray-700 dark:hover:bg-slate-500 focus:bg-gray-700 dark:focus:bg-slate-500 active:bg-gray-900 dark:active:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:focus:ring-offset-slate-300 transition ease-in-out duration-150 ${
          disabled && "opacity-25"
        } ` + className
      }
      disabled={disabled}
    >
      {children}
    </button>
  );
}
