import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const useRole = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [roles, setRoles] = useState([]);

  const handleError = (e) => {
    setIsError(true);
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };
  const handleFinish = () => {
    setIsLoading(false);
  };
  const handleInitial = () => {
    setIsLoading(true);
  };

  const fetchAll = async () => {
    handleInitial();
    await instanceRequest("get", "role", undefined, undefined)
      .then((r) => {
        setIsSuccess(true);
        setRoles(r.data);
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const assign = async (user_id, role_id) => {
    handleInitial();
    await instanceRequest("post", "role/assign", undefined, {
      user_id: user_id,
      role_id: role_id,
    })
      .then(() => {
        setIsSuccess(true);
        toast.success(t("User has been assigned to role"));
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  const revoke = async (user_id, role_id) => {
    handleInitial();
    await instanceRequest("post", "role/revoke", undefined, {
      user_id: user_id,
      role_id: role_id,
    })
      .then((r) => {
        setIsSuccess(true);
        toast.success(t("The user's role has been revoked"));
      })
      .catch((e) => handleError(e))
      .finally(() => handleFinish());
  };

  return {
    isLoading,
    isError,
    isSuccess,
    roles,
    fetchAll,
    assign,
    revoke,
  };
};
